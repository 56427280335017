import { ActionReducer, MetaReducer } from '@ngrx/store';
import { environment } from '../environments/environment';

export function logger(reducer: ActionReducer<any>): ActionReducer<any> {
  return function (state: any, action: any): any {
    const newState = reducer(state, action);
    console.debug('action', action);
    console.debug('state', newState);
    return newState;
  };
}

export const metaReducers: MetaReducer<any>[] = environment.production ? [] : [logger];
