import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest, Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { Product, ProductsListRequest, ProductsListResponse, ProductsUsageData, ProductUpdateLogoRequest } from 'src/app/core/models/products.models';
import {
  ProductsReqListAction,
  ProductsReqListErrorAction,
  ProductsReqListSuccessAction,
  ProductsReqSaveAction,
  ProductsReqSaveErrorAction,
  ProductsReqSaveLogoAction,
  ProductsReqSaveLogoErrorAction,
  ProductsReqSaveLogoSuccessAction,
  ProductsReqSaveSuccessAction,
  ProductsSelectByIdAction,
} from 'src/app/core/store/actions/products';
import {
  getProductsIdsSelector,
  getProductsListSelector,
  getProductsSavingIdSelector,
  getProductsSelectedIdSelector,
  getProductsSelector,
  getSelectedProductSelector,
} from 'src/app/core/store/reducers/products';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { ProductsBackendService } from '../../services/backend/products-backend.service';
import { AuthLogoutSuccessAction } from '../actions/auth';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class ProductsStoreService {
  public readonly map$ = this.store$.pipe(select(getProductsSelector));
  public readonly ids$ = this.store$.pipe(select(getProductsIdsSelector));
  public readonly selected$ = this.store$.pipe(select(getSelectedProductSelector));
  public readonly savingId$ = this.store$.pipe(select(getProductsSavingIdSelector));
  public readonly selectedId$ = this.store$.pipe(select(getProductsSelectedIdSelector));

  public readonly list$: Observable<Product[]>;
  public readonly activeList$: Observable<Product[]>;
  public readonly activeData$: Observable<ProductsUsageData>;

  constructor(
    private store$: Store<CoreState>, //
    private pss: PortalStoreService,
    private bs: ProductsBackendService,
  ) {
    this.list$ = combineLatest([
      this.store$.pipe(select(getProductsListSelector)), //
      this.pss.portal$,
    ]).pipe(
      map(([products, portal]) => {
        if (!portal?.id) return products;
        return products.filter((p) => p.portalId === portal.id);
      }),
    );

    this.activeList$ = this.list$.pipe(
      map((products) => {
        return products.filter((p) => p.isActive);
      }),
    );

    this.activeData$ = combineLatest([
      this.activeList$, //
      this.selectedId$,
    ]).pipe(
      map(([products, selectedId]) => {
        if (!selectedId) return { products, selectedId: products[0]?.productId };
        const active = products.find((p) => p.productId === selectedId) || products[0];
        return { products, active, selectedId: active?.productId };
      }),
    );
  }

  public selectById(id: string) {
    this.store$.dispatch(new ProductsSelectByIdAction(id));
  }

  public load(req: ProductsListRequest): Observable<ProductsListResponse> {
    this.store$.dispatch(new ProductsReqListAction(req));
    return this.bs.getProducts(req).pipe(
      map((productsRes) => {
        this.store$.dispatch(new ProductsReqListSuccessAction(productsRes));
        return productsRes;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ProductsReqListErrorAction(err));
        if (err.status === 403) {
          this.store$.dispatch(new AuthLogoutSuccessAction());
        }
        return throwError(() => err);
      }),
    );
  }

  public update(product: Product): Observable<Product> {
    this.store$.dispatch(new ProductsReqSaveAction(product));

    return this.bs.update(product).pipe(
      map((res) => {
        this.store$.dispatch(new ProductsReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new ProductsReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public changeLogo(productId: string, imgFile?: File): Observable<void> {
    const obs: Observable<string | null> = imgFile ? this.bs.uploadLogo(productId, imgFile) : of(null);

    return obs.pipe(
      switchMap((portalFileId) => {
        const req: ProductUpdateLogoRequest = { productId, portalFileId };
        this.store$.dispatch(new ProductsReqSaveLogoAction(req));
        return this.bs.updateLogo(req).pipe(
          map(() => {
            this.store$.dispatch(new ProductsReqSaveLogoSuccessAction());
          }),
          catchError((err: ErrorResponse) => {
            this.store$.dispatch(new ProductsReqSaveLogoErrorAction(err));
            return throwError(() => err);
          }),
        );
      }),
    );
  }
}
