import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { PermissionsResponse, PromoteUserToRoleRequest } from 'src/app/core/models/permissions.models';
import { Portal, PortalUser, UpdatePortalUser } from 'src/app/core/models/portal.models';
import { PermissionsBackendService } from 'src/app/core/services/backend/permissions-backend.service';
import { getPermissionsUsersListSelector, getPermissionsUsersSelector } from 'src/app/core/store/reducers/permissions';
import { getPortalSelector } from 'src/app/core/store/reducers/portal';
import {
  PermissionsReqAddAction,
  PermissionsReqAddErrorAction,
  PermissionsReqAddSuccessAction,
  PermissionsReqDelAction,
  PermissionsReqDelErrorAction,
  PermissionsReqDelSuccessAction,
  PermissionsReqListAction,
  PermissionsReqListErrorAction,
  PermissionsReqListSuccessAction,
  PermissionsReqSaveAction,
  PermissionsReqSaveErrorAction,
  PermissionsReqSaveSuccessAction,
  PromoteUserToPortalRoleAction,
  PromoteUserToPortalRoleErrorAction,
  PromoteUserToPortalRoleSuccessAction,
} from '../actions/permissions';
import { CoreState } from '../reducers';

@Injectable({
  providedIn: 'root',
})
export class PermissionsStoreService {
  public readonly users$ = this.store$.pipe(select(getPermissionsUsersSelector));
  public readonly list$ = this.store$.pipe(select(getPermissionsUsersListSelector));

  constructor(
    private store$: Store<CoreState>, //
    private pbs: PermissionsBackendService,
  ) {}

  public load(): Observable<PermissionsResponse> {
    return this.store$.pipe(
      select(getPortalSelector),
      filter((p): p is Portal => !!p),
      take(1),
      switchMap((portal) => {
        this.store$.dispatch(new PermissionsReqListAction(portal.id!));
        return this.pbs.getList(portal.id!).pipe(
          map((res) => {
            this.store$.dispatch(new PermissionsReqListSuccessAction(res));
            return res;
          }),
          catchError((err: ErrorResponse) => {
            this.store$.dispatch(new PermissionsReqListErrorAction(err));
            return throwError(() => err);
          }),
        );
      }),
    );
  }

  public addUser(user: PortalUser) {
    this.store$.dispatch(new PermissionsReqAddAction(user));

    return this.pbs.add(user).pipe(
      map((res) => {
        this.store$.dispatch(new PermissionsReqAddSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PermissionsReqAddErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public updateUser(user: PortalUser) {
    this.store$.dispatch(new PermissionsReqSaveAction(user));

    return this.pbs.update(user).pipe(
      map((res) => {
        this.store$.dispatch(new PermissionsReqSaveSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PermissionsReqSaveErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public deleteUser(user: PortalUser) {
    this.store$.dispatch(new PermissionsReqDelAction(user));

    return this.pbs.delete(user).pipe(
      map((res) => {
        this.store$.dispatch(new PermissionsReqDelSuccessAction(res));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PermissionsReqDelErrorAction(err));
        return throwError(() => err);
      }),
    );
  }

  public promoteUserToRole(req: PromoteUserToRoleRequest) {
    this.store$.dispatch(new PromoteUserToPortalRoleAction(req));

    return this.pbs.promoteUserToRole(req).pipe(
      map((res) => {
        this.store$.dispatch(new PromoteUserToPortalRoleSuccessAction(req));
        return res;
      }),
      catchError((err: ErrorResponse) => {
        this.store$.dispatch(new PromoteUserToPortalRoleErrorAction(err));
        return throwError(() => err);
      }),
    );
  }
}
