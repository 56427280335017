<div class="dialog-wrap">
  <button type="button" (click)="close()" class="btn btn-close text-size-15">
    <craft-icon class="svg-icon" name="general--close-md"></craft-icon>
  </button>

  <h3 mat-dialog-title class="dialog-title">
    Users list <span *ngIf="company.usersCount > 0">({{ company.usersCount }})</span>
  </h3>

  <div mat-dialog-content="" class="dialog-content">
    <cdk-virtual-scroll-viewport itemSize="72" class="viewport" *ngIf="company.usersCount > 0; else empty">
      <div *cdkVirtualFor="let user of ds; let i = index" class="user-item">
        <ng-container *ngTemplateOutlet="!user ? loading : loaded; context: { user: user, idx: i }"></ng-container>
      </div>
    </cdk-virtual-scroll-viewport>
  </div>
</div>

<ng-template #loading>
  <craft-line-loading class="placeholder">
    <div class="circle animate"></div>
    <div class="lines">
      <div class="line animate" style="width: 113px"></div>
      <div class="line animate" style="width: 191px"></div>
    </div>
  </craft-line-loading>
</ng-template>

<ng-template #loaded let-user="user" let-idx="idx">
  <craft-company-user-row [user]="user" [showChangeRole]="true" [showMoveToCompany]="true" [rowIndex]="idx"></craft-company-user-row>
</ng-template>

<ng-template #empty>
  <h3 class="empty">There are no users to manage</h3>
</ng-template>
