import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UserRoleView } from 'src/app/core/enums/UserRole';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './change-user-role-dialog.component.html',
  styleUrls: ['./change-user-role-dialog.component.scss'],
})
export class ChangeUserRoleDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: PortalUser | CompanyUser; targetRole: UserRoleView }, //
    private dialogRef: MatDialogRef<ChangeUserRoleDialogComponent, DialogResult>,
  ) {}

  public close() {
    this.dialogRef.close({ done: false });
  }

  public done() {
    this.dialogRef.close({ done: true });
  }
}
