<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Promote to Administrative User</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle">
      Are you sure you want to promote <b>{{ data.user.fullName }}</b> to <b>{{ data.targetRole.name | craftTerm }}</b
      >?
      <p class="mt-10">
        Please note that promoting to an administrative role significantly increases access to internal information, such as other users' feedback and data from
        multiple companies.
      </p>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" (click)="done()" mat-button="">Confirm</button>
    </div>
  </div>
</div>
