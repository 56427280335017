import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { SYSTEM_COLORS } from 'src/app/core/dictionaries/colors';
import { IdeasVisibility } from 'src/app/core/enums/IdeaVisibility';
import { UserRole } from 'src/app/core/enums/UserRole';
import { Category } from 'src/app/core/models/categories.models';
import { Comment } from 'src/app/core/models/comments.models';
import { Company, CompanyUser, CompanyUserStatus } from 'src/app/core/models/companies.models';
import { CustomField } from 'src/app/core/models/custom-fields.models';
import { CraftForm } from 'src/app/core/models/forms.models';
import { Idea, IdeaCustomFieldValue } from 'src/app/core/models/idea.models';
import { Importance } from 'src/app/core/models/importances.models';
import { Note } from 'src/app/core/models/notes.models';
import { Portal, PortalUser } from 'src/app/core/models/portal.models';
import { Tag } from 'src/app/core/models/tags.models';
import { WorkflowStatus } from 'src/app/core/models/workflow-statuses.models';
import { AuthStoreService } from 'src/app/core/store/services/auth-store.service';
import { CustomFieldsStoreService } from 'src/app/core/store/services/custom-fields-store.service';
import { PortalStoreService } from 'src/app/core/store/services/portal-store.service';
import { ProductsStoreService } from 'src/app/core/store/services/products-store.service';
import { getOrderIndex, getRandomItem, omitEmptyProps } from 'src/app/core/utils/app-utils';
import { createGuid } from 'src/app/core/utils/crypto-utils';

type ProductData = {
  productId: string;
  productKey: string;
};

@Injectable({ providedIn: 'root' })
export class EntityFactory {
  constructor(
    private authStore: AuthStoreService,
    private portalStore: PortalStoreService,
    private productsStore: ProductsStoreService,
    private customFieldsService: CustomFieldsStoreService,
  ) {}

  public createCategory(template?: Partial<Readonly<Category>>): Observable<Category> {
    return forkJoin([
      this.portal$, //
      this.creator$,
      this.productData$,
    ]).pipe(
      map(([portal, creator, productData]) => {
        const creatorId = creator.id;

        const category: Category = {
          name: '',
          createdDt: new Date(),
          creatorId,
          editedDt: new Date(),
          editorId: creatorId,
          ideasCnt: 0,
          trend: 0,
          isDefault: false,
          lastUpdate: new Date(),
          orderIndex: -Date.now(),
          companies: [],
          companiesCnt: 0,
          companiesNames: [],
          isPrivate: false,
          ideaVisibility: !portal.crossCompany ? IdeasVisibility.visibleToCreators : IdeasVisibility.visibleToAllUsers,
          ...omitEmptyProps<ProductData>(productData),
          ...omitEmptyProps<Partial<Category>>(template),
        };

        return category;
      }),
    );
  }

  public createIdea(template?: Partial<Idea>): Observable<Idea> {
    return forkJoin([
      this.creator$, //
      this.productData$,
      this.customFieldsService.enabledList$.pipe(take(1)),
    ]).pipe(
      map(([creator, productData, customFields]) => {
        const custom: ReadonlyArray<Readonly<IdeaCustomFieldValue>> = customFields.map((f) => ({
          id: f.id,
          guid: f.guid,
          value: null,
        }));

        const idea: Idea = {
          custom,
          creator,
          commentsCnt: 0,
          createdDt: new Date(),
          isPublic: false,
          isVoted: false,
          isTracked: false,
          isArchived: false,
          labels: [],
          notesCnt: 0,
          trend: 0,
          votes: 0,
          linkedCnt: 0,
          attaches: [],
          ...omitEmptyProps<ProductData>(productData),
          ...omitEmptyProps<Partial<Idea>>(template),
        };

        return idea;
      }),
    );
  }

  public createComment(ideaId: string, html: string, text: string, userIds: readonly string[]): Observable<Comment> {
    return this.creator$.pipe(
      map((creator) => {
        const com: Comment = {
          ideaId,
          creator,
          publicationDt: new Date(),
          comment: html,
          clearText: text,
          mentions: userIds,
        };

        return com;
      }),
    );
  }

  public createNote(ideaId: string, html: string, text: string, userIds: readonly string[]): Observable<Note> {
    return this.creator$.pipe(
      map((creator) => {
        const note: Note = {
          ideaId,
          creator,
          publicationDt: new Date(),
          comment: html,
          clearText: text,
          mentions: userIds,
        };

        return note;
      }),
    );
  }

  public createTag(title = ''): Observable<Tag> {
    return this.productData$.pipe(
      map(({ productId }) => {
        const tag: Tag = {
          id: void 0,
          title,
          productId,
          ideasCount: 0,
        };

        return tag;
      }),
    );
  }

  public createImportance(lastImportance?: Importance): Observable<Importance> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: Importance = {
          portalId,
          isDefault: false,
          orderIndex: getOrderIndex(lastImportance, void 0, 'orderIndex'),
        };

        return res;
      }),
    );
  }

  public createWorkflowStatus(lastStatus?: WorkflowStatus): Observable<WorkflowStatus> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: WorkflowStatus = {
          portalId,
          isDefault: false,
          orderIndex: getOrderIndex(lastStatus, void 0, 'orderIndex'),
          color: getRandomItem(SYSTEM_COLORS as any[]),
        };

        return res;
      }),
    );
  }

  public createCompany(): Observable<Company> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: Company = {
          name: '',
          portalId,
          createdDt: new Date(),
          isActive: true,
          isDefault: false,
          usersCount: 0,
          productsCount: 0,
          invitationLinksCount: 0,
          products: {},
          invitationLinks: {},
        };

        return res;
      }),
    );
  }

  public createForm(): Observable<CraftForm> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: CraftForm = {
          id: void 0,
          name: '',
          portalId,
          isDefault: false,
          createdDt: new Date(),
          fields: [{ id: 'description', isRequired: false, orderIndex: 0 }],
          categories: [],
        };

        return res;
      }),
    );
  }

  public createCompanyUser(company: Company, template?: Partial<CompanyUser>): Observable<CompanyUser> {
    const res: CompanyUser = {
      id: void 0,
      email: void 0,
      invitedDt: new Date(),
      companyId: company.id as string,
      iportalRole: void 0,
      portalId: company.portalId,
      status: CompanyUserStatus.invited,
      ...template,
    };

    return of(res);
  }

  public createPortalUser(email: string): Observable<PortalUser> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: PortalUser = {
          portalId,
          email,
          iportalRole: UserRole.Contributor,
          feedbacksCount: 0,
        };

        return res;
      }),
    );
  }

  public createCustomField(template?: Partial<CustomField>): Observable<Partial<CustomField>> {
    return this.portalId$.pipe(
      map((portalId) => {
        const res: Partial<CustomField> = {
          portalId,
          guid: createGuid(),
          type: 'TEXT',
          label: '',
          isEnabled: true,
          isReadOnly: false,
          isPredefault: false,
          placeholder: 'Enter your answer',
          validation: [],
          maxLength: 1000,
          controlType: 'STRINGINPUT',
          compatibleWith: [],
          ...omitEmptyProps<Partial<CustomField>>(template),
        };
        return res;
      }),
    );
  }

  private get portal$(): Observable<Portal> {
    return this.portalStore.portal$.pipe(take(1)).pipe(
      map((portal) => {
        if (!portal) {
          throw new Error('Current portal is not defined!');
        }
        return portal;
      }),
    );
  }

  private get portalId$(): Observable<string> {
    return this.portal$.pipe(
      map((portal) => {
        if (!portal.id) {
          throw new Error('Current portal id is not defined!');
        }
        return portal.id;
      }),
    );
  }

  private get creator$(): Observable<PortalUser> {
    return forkJoin([
      this.portalId$, //
      this.authStore.currentUser$.pipe(take(1)),
    ]).pipe(
      map(([portalId, currentUser]) => {
        if (!currentUser) {
          throw new Error('Current user is not defined!');
        }

        return {
          ...currentUser,
          portalId,
        };
      }),
    );
  }

  private get productData$(): Observable<ProductData> {
    return this.productsStore.selected$.pipe(take(1)).pipe(
      map((selectedProduct) => {
        if (!selectedProduct) {
          throw new Error('Current product is not selected!');
        }

        return {
          productId: selectedProduct.productId,
          productKey: selectedProduct.key,
        };
      }),
    );
  }
}
