import { PickRequired, StringTMap } from 'src/app/core/models/common.models';

export enum CompanyUserStatus {
  invited = 1,
  signed = 2,
  selfSigned = 3,
  invitedMultiLink = 4,
}

export type Company = {
  readonly id?: string;
  readonly name: string;
  readonly createdDt: Date;
  readonly isActive: boolean;
  readonly isDefault: boolean;
  readonly ownerId?: string;
  readonly portalId: string;
  readonly usersCount: number;
  readonly importance?: number;
  readonly size?: string;
  readonly inviteSid?: string;
  readonly invitationLinksCount: number;
  readonly invitationLinks: StringTMap<CompanyInviteLink>;
  readonly productsCount: number;
  readonly products: StringTMap<CompanyProduct>;
};

export type DBCompany = Company & { readonly id: string };

export type CompanyUser = {
  readonly id?: string;
  readonly email?: string;
  readonly invitedDt?: Date;
  readonly companyId: string;
  readonly confirmed?: boolean;
  readonly invitedById?: string;
  readonly iportalRole?: string;
  readonly portalId: string;
  readonly status: CompanyUserStatus;
  readonly lastName?: string;
  readonly firstName?: string;
  readonly fullName?: string;
  readonly color?: string;
  readonly signUpDt?: Date;
  readonly feedbacksCount?: number;
  readonly addedBy?: 'link' | 'self' | 'invite';
};

export type UpdateCompanyUser = PickRequired<CompanyUser, 'id'> | PickRequired<CompanyUser, 'email'>;

export type CompanyAdmin = {
  readonly id?: string;
  readonly portalId: string;
  readonly personId?: string;
  readonly companyId?: string;
  readonly canManage?: boolean;
  readonly isAccessAllowed?: boolean;
};

export type CompanyProduct = {
  readonly name: string;
  readonly isActive: boolean;
  readonly portalId: string;
  readonly productId: string;
  readonly productFile?: string;
  readonly portalFileId?: string;
};

export type CompanyInviteLink = {
  readonly id?: string;
  readonly sid: string;
  readonly companyId: string;
  readonly createdBy: string;
  readonly createdDt: string | Date;
  readonly domain: string;
  readonly portalId: string;
  readonly count: number;
};

export type CompaniesListRequest = {
  readonly withArchival?: boolean;
  readonly withManagementFilter?: boolean;
};

export type CompaniesListResponse = {
  readonly ids: string[];
  readonly companies: StringTMap<DBCompany>;
};

export type CompanyDelRequest = {
  readonly companyId: string;
  readonly isArchival?: boolean;
};

export type CompaniesUsersListRequest = {
  readonly companyId: string;
  readonly total: number;
  readonly skip: number;
  readonly limit: number;
};

export type MoveToCompanyRequest = { fromCompanyId: string; toCompanyId: string; email: string };

export type CompaniesUsersListResponse = CompaniesUsersListRequest & {
  readonly users: readonly CompanyUser[];
};

export type CompanyAddInviteLinkRequest = {
  readonly companyId: string;
  readonly domain: string;
};

export type CompanyDelInviteLinkRequest = {
  readonly linkId: string;
  readonly companyId: string;
  readonly deleteUsers: boolean;
};
