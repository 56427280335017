import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { USER_ROLES_DISPLAYED_LIST, UserRole, UserRoleView } from 'src/app/core/enums/UserRole';
import { ErrorResponse } from 'src/app/core/models/common.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { EntityFactory } from 'src/app/core/store/services/entity.factory';
import { PermissionsStoreService } from 'src/app/core/store/services/permissions-store.service';
import { DialogResult } from 'src/app/shared/dialogs/models';

type FormValue = {
  email: string;
  iportalRole: UserRole;
};

@Component({
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss'],
})
export class InviteUserDialogComponent implements OnInit, OnDestroy {
  public isSubmitted = false;
  public serverError?: ErrorResponse;
  public readonly roles: ReadonlyArray<UserRoleView> = USER_ROLES_DISPLAYED_LIST;

  public readonly inviteForm = new UntypedFormGroup({
    email: new UntypedFormControl(void 0, [Validators.required, Validators.email]),
    iportalRole: new UntypedFormControl(UserRole.Reviewer, [Validators.required]),
  });

  private selectedUser?: PortalUser;
  private readonly destroy$ = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<InviteUserDialogComponent, DialogResult>,
    private permissionsStore: PermissionsStoreService,
    private entityFactory: EntityFactory,
  ) {}

  public ngOnInit() {}

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public selectUser(user: PortalUser) {
    this.selectedUser = user;
  }

  public close(): void {
    this.dialogRef.close({ done: false });
  }

  public done(): void {
    this.isSubmitted = true;
    this.serverError = void 0;
    if (this.inviteForm.invalid) return;

    this.inviteForm.disable();
    const { email, iportalRole } = this.inviteForm.value as FormValue;

    of(this.selectedUser)
      .pipe(
        switchMap((user) => {
          return user?.email === email ? of(user) : this.entityFactory.createPortalUser(email);
        }),
        switchMap((user) => {
          return this.permissionsStore.addUser({ ...user, iportalRole });
        }),
        takeUntil(this.destroy$),
      )
      .subscribe({
        next: () => {
          this.dialogRef.close({ done: true });
        },
        error: (err: ErrorResponse) => {
          this.serverError = err;
          this.inviteForm.enable();
        },
      });
  }
}
