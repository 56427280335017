import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CompanyUser } from 'src/app/core/models/companies.models';

@Component({
  selector: 'craft-company-user-row',
  templateUrl: './company-user-row.component.html',
  styleUrls: ['./company-user-row.component.scss'],
})
export class CompanyUserRowComponent {
  @Input() public user: CompanyUser;
  @Input() public rowIndex: number;
  @Input() public showChangeRole = false;
  @Input() public showMoveToCompany = false;
  @Output() public userDeleted = new EventEmitter<void>();
  @Output() public movedToCompany = new EventEmitter<void>();
  @Output() public roleChanged = new EventEmitter<void>();

  constructor() {}
}
