import { StringAnyMap, StringBooleanMap } from 'src/app/core/models/common.models';

export function getRandomItem<T>(items: readonly T[]) {
  return items[Math.floor(Math.random() * items.length)];
}

export function getOrderIndex(prev?: any, next?: any, key = 'orderIndex', mul = 1): number {
  if (prev && next) {
    return (Number.parseFloat(prev[key]) + Number.parseFloat(next[key])) / 2;
  } else if (prev) {
    return Number.parseFloat(prev[key]) + 1000 * mul;
  } else if (next) {
    return Number.parseFloat(next[key]) - 1000 * mul;
  } else {
    return -Date.now();
  }
}

export function getAppDomainName(hostname = window.location.hostname): string {
  const sub = hostname.toLowerCase().split('.')[1] || '';
  const split = sub.split('-');

  const eu = split[0] === 'feedback';
  const pp = split[1] ? `-${split[1]}` : '';

  return eu ? `app-eu${pp}.craft.io` : `app${pp}.craft.io`;
}

export function getPortalDomainName(hostname = window.location.hostname): string {
  const sub = hostname.toLowerCase().split('.')[0] || '';
  const split = sub.split('-');

  const eu = split[1] === 'eu';
  const pp = eu ? split[2] : split[1];
  const ppd = pp ? `-${pp}` : '';

  return eu ? `feedback${ppd}.craft.io` : `ideas${ppd}.craft.io`;
}

export function getAppUrl(subUrl?: string, productId?: string, itemId?: string): string {
  const domain = getAppDomainName();

  if (subUrl && productId && itemId) {
    return `//${domain}/${subUrl.replace('@productId', productId).replace('@itemId', itemId)}`;
  }

  if (subUrl && productId) {
    return `//${domain}/${subUrl.replace('@productId', productId)}/`;
  }

  return `//${domain}`;
}

export function arrayToBooleanHash(arr: readonly any[], keyName: string): StringBooleanMap {
  return arr.reduce((acc, item) => {
    acc[item[keyName]] = true;
    return acc;
  }, {});
}

export function indexBy<T extends StringAnyMap>(arr: readonly T[], key: keyof T) {
  return arr.reduce((map, item) => {
    map.set(item[key], item);
    return map;
  }, new Map<string, T>());
}

export function indexByToStringMap<T extends StringAnyMap>(arr: readonly T[], key: keyof T) {
  return arr.reduce(
    (map, item) => {
      map[item[key]] = item;
      return map;
    },
    {} as Record<string, T>,
  );
}

export function omitEmptyProps<T extends StringAnyMap>(object: T | undefined): T {
  if (typeof object === 'object' && object !== null) {
    return Object.keys(object).reduce((acc, key) => {
      if (object[key] !== null && object[key] !== undefined) {
        (acc as any)[key] = object[key];
      }
      return acc;
    }, {} as T);
  }
  return {} as T;
}

export function mergeArrays<T extends StringAnyMap>(key: string, a: readonly T[] = [], b: readonly T[] = []): T[] {
  return Array.from(indexBy([...a, ...b], key).values());
}

export function isNumber(val: any): boolean {
  return typeof val === 'number' && !Number.isNaN(val) && Number.isFinite(val);
}

export function isDefined(val: any): boolean {
  return typeof val !== 'undefined' && val !== null;
}

export function isObject(item: any): boolean {
  return item && typeof item === 'object' && !Array.isArray(item);
}

export function isEmpty(val: any): boolean {
  return val === '' || val === void 0 || val === null;
}

export function toNumber(val: any, min = Number.MIN_SAFE_INTEGER, max = Number.MAX_SAFE_INTEGER): number {
  if (isNumber(val)) return Math.min(Math.max(val, min), max);
  if (!val) return min;

  const parsed = Number.parseFloat(String(val));
  return toNumber(parsed, min, max);
}

export function assignDefined(source: StringAnyMap): StringAnyMap {
  return Object.keys(source).reduce((acc, key) => {
    if (source[key] !== void 0) acc[key] = source[key];
    return acc;
  }, {} as StringAnyMap);
}
