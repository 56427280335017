import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CompanyUser } from 'src/app/core/models/companies.models';
import { CompaniesAction } from 'src/app/core/store/actions/companies';
import {
  COMPANY_DEL_USER_SUCCESS,
  COMPANY_MOVE_USER_TO_COMPANY_SUCCESS,
  COMPANY_USERS_LIST,
  COMPANY_USERS_LIST_CLEAR,
  COMPANY_USERS_LIST_SUCCESS,
} from 'src/app/core/store/actions/company-users';
import { PERMISSIONS_PROMOTE_ROLE } from 'src/app/core/store/actions/permissions';

export type CompanyUsersState = {
  readonly users: { [companyId: string]: (CompanyUser | undefined)[] };
};

export const initialState: CompanyUsersState = {
  users: {},
};

export function reducer(state = initialState, action: CompaniesAction): CompanyUsersState {
  switch (action.type) {
    case COMPANY_USERS_LIST_CLEAR: {
      delete state.users[action.payload];
      return {
        ...state,
        users: {
          ...state.users,
        },
      };
    }

    case COMPANY_USERS_LIST: {
      const { companyId, total } = action.payload;
      const users = state.users[companyId] || Array.from({ length: total });
      return {
        ...state,
        users: {
          ...state.users,
          [companyId]: users,
        },
      };
    }

    case COMPANY_USERS_LIST_SUCCESS: {
      const { companyId, skip, limit, users } = action.payload;
      const cache = state.users[companyId];
      cache.splice(skip, limit, ...users);
      return {
        ...state,
        users: {
          ...state.users,
          [companyId]: cache,
        },
      };
    }

    case COMPANY_DEL_USER_SUCCESS: {
      const user = action.payload;
      const companyId = user.companyId;
      const users = state.users[companyId]?.filter((u) => u !== user) || [];
      return {
        ...state,
        users: {
          ...state.users,
          [companyId]: users,
        },
      };
    }

    case PERMISSIONS_PROMOTE_ROLE: {
      const req = action.payload;
      if (!req.fromCompanyId) return state;

      return {
        ...state,
        users: {
          ...state.users,
          [req.fromCompanyId]: state.users[req.fromCompanyId]?.filter((u) => u?.email !== req.email) || [],
        },
      };
    }

    case COMPANY_MOVE_USER_TO_COMPANY_SUCCESS: {
      const req = action.payload;
      const users = Object.values(state.users);
      const oldUser = users.flat().find((u) => u?.email === req.email);
      if (!oldUser) return state;

      const oldCompanyId = req.fromCompanyId;
      const newCompanyId = req.toCompanyId;
      return {
        ...state,
        users: {
          ...state.users,
          [oldCompanyId]: state.users[oldCompanyId]?.filter((u) => u !== oldUser) || [],
          [newCompanyId]: [...(state.users[newCompanyId] || []), { ...oldUser, companyId: newCompanyId }],
        },
      };
    }

    default:
      return state;
  }
}

const getUsers = (state: CompanyUsersState) => state.users;

export const getCompanyUsersStateSelector = createFeatureSelector<CompanyUsersState>('companyUsersState');
export const getCompanyUsersSelector = createSelector(getCompanyUsersStateSelector, getUsers);
