import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { DBCompany } from 'src/app/core/models/companies.models';
import { CompaniesUsersStoreService } from 'src/app/core/store/services/companies-store-users.service';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { CompanyUsersDataSource } from 'src/app/shared/dialogs/company-users-dialog/company-users.data-source';

@Component({
  templateUrl: './company-users-dialog.component.html',
  styleUrls: ['./company-users-dialog.component.scss'],
})
export class CompanyUsersDialogComponent implements OnInit, OnDestroy {
  public company: DBCompany;
  public ds: CompanyUsersDataSource;

  private destroy$ = new Subject<void>();

  constructor(
    private readonly companiesStore: CompaniesStoreService,
    private readonly usersStore: CompaniesUsersStoreService,
    @Inject(MAT_DIALOG_DATA) public readonly companyId: string,
    private readonly dialogRef: MatDialogRef<CompanyUsersDialogComponent, void>,
  ) {}

  public ngOnInit() {
    this.companiesStore.map$
      .pipe(
        map((companies) => companies[this.companyId]),
        takeUntil(this.destroy$),
      )
      .subscribe((company) => {
        this.company = company;
      });

    this.ds = new CompanyUsersDataSource(this.companyId, this.usersStore);
  }

  public ngOnDestroy() {
    this.ds.disconnect();
    this.destroy$.next();
    this.destroy$.complete();
    this.usersStore.clearUsersCache(this.companyId);
  }

  public close(): void {
    this.dialogRef.close();
  }
}
