<div class="row">
  <div class="info">
    <span>{{ rowIndex + 1 }}</span>
    <ng-container *ngTemplateOutlet="user.id ? regularUser : invitedUser; context: { $implicit: user }"></ng-container>
  </div>

  <div [ngSwitch]="user.addedBy" class="status">
    <span *ngSwitchCase="'link'">Added via company link</span>
  </div>

  <div class="dropdowns">
    <craft-manage-user-menu
      [user]="user"
      [showChangeRole]="showChangeRole && !!user.id"
      [showMoveToCompany]="showMoveToCompany && !!user.id"
      (userDeleted)="userDeleted.next()"
      (movedToCompany)="movedToCompany.next()"
      (roleChanged)="roleChanged.next()"
    ></craft-manage-user-menu>
  </div>
</div>

<ng-template #regularUser let-user>
  <craft-user-info [user]="user"></craft-user-info>
</ng-template>

<ng-template #invitedUser let-user>
  <craft-invited-user [user]="user"></craft-invited-user>
</ng-template>
