import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { StringTMap } from 'src/app/core/models/common.models';
import { DBTag, Tag, TagsAddBulkRequest, TagsListRequest, TagsListResponse } from 'src/app/core/models/tags.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';

@Injectable({
  providedIn: 'root',
})
export class TagsBackendService {
  constructor(private bs: BackendService) {}

  public getProductList(req: TagsListRequest): Observable<TagsListResponse> {
    const { productId, itemscount = false } = req;
    return this.bs.get('iportal/label/', { productId, itemscount }).pipe(
      map((result) => {
        return this.toListResponse(result.list);
      }),
    );
  }

  public add(tag: Tag): Observable<DBTag> {
    return this.bs.post(`iportal/label`, tag).pipe(
      map((res) => {
        return { ...tag, id: res.id } as DBTag;
      }),
    );
  }

  public addBulk(req: TagsAddBulkRequest): Observable<TagsListResponse> {
    const { productId, tags: labels } = req;
    return this.bs.post(`iportal/labels`, { productId, labels }).pipe(
      map((result) => {
        return this.toListResponse(result.labels);
      }),
    );
  }

  public update(tag: DBTag): Observable<DBTag> {
    return this.bs.put(`iportal/label/${tag.id}`, tag).pipe(
      map(() => {
        return tag;
      }),
    );
  }

  public delete(tag: DBTag): Observable<string> {
    return this.bs.delete(`iportal/label/${tag.id}`).pipe(map(() => tag.id));
  }

  private toListResponse(list: readonly DBTag[]) {
    const tags: StringTMap<DBTag> = {};

    const ids = list.reduce((acc: string[], tag: any) => {
      tags[tag.id] = this.mapTag(tag);
      acc.push(tag.id);
      return acc;
    }, []);

    return { ids, tags };
  }

  private mapTag(tag: any): DBTag {
    const ideasCount = Number.parseInt(tag.ideasCount, 10) || 0;
    tag.archivedIdeasCount = Number.parseInt(tag.archivedIdeasCount, 10) || 0;
    tag.ideasCount = ideasCount - tag.archivedIdeasCount;
    return tag;
  }
}
