import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompaniesUsersListRequest, CompanyUser, CompanyUserStatus, MoveToCompanyRequest } from 'src/app/core/models/companies.models';
import { BackendService } from 'src/app/core/services/backend/backend.service';
import { getFullName } from 'src/app/core/utils/text-utils';

@Injectable({
  providedIn: 'root',
})
export class CompanyUsersBackendService {
  constructor(private bs: BackendService) {}

  public getUsersList(req: CompaniesUsersListRequest): Observable<CompanyUser[]> {
    return this.bs.get(`company/users/${req.companyId}`, { skip: req.skip, limit: req.limit }).pipe(
      map((data) => {
        return this.mapList(data);
      }),
    );
  }

  public getRegisteredUsersList(): Observable<CompanyUser[]> {
    return this.bs.get(`company/allUsers/`).pipe(
      map((data) => {
        return this.mapList(data);
      }),
    );
  }

  public getCompanyUsersList(): Observable<CompanyUser[]> {
    return this.bs.get(`company/allCompanyUsers/`).pipe(
      map((data) => {
        return this.mapList(data);
      }),
    );
  }

  public addUser(user: CompanyUser): Observable<CompanyUser> {
    return this.bs
      .post(`company/invite`, {
        companyId: user.companyId,
        toEmail: user.email,
      })
      .pipe(
        map((res) => {
          return this.mapUser(res);
        }),
      );
  }

  public deleteUser(user: CompanyUser): Observable<void> {
    return this.bs
      .delete(`company/invite`, {
        companyId: user.companyId,
        toEmail: user.email ? window.encodeURIComponent(user.email) : void 0,
      })
      .pipe(map(() => {}));
  }

  public moveToCompany(req: MoveToCompanyRequest): Observable<void> {
    return this.bs.put(`company/moveUser`, req).pipe(map(() => {}));
  }

  public resendInvite(user: CompanyUser): Observable<void> {
    return this.bs
      .post(`company/resend`, {
        companyId: user.companyId,
        toEmail: user.email,
      })
      .pipe(map(() => {}));
  }

  private mapUser(user: any): CompanyUser {
    if (user.invitedDt) {
      user.invitedDt = new Date(user.invitedDt);
    }
    if (user.signUpDt) {
      user.signUpDt = new Date(user.signUpDt);
    }

    user.status = Number.parseInt(user.status, 0) || 0;
    user.fullName = getFullName(user);
    user.feedbacksCount = user.feedbacksCount | 0;

    if (user.status === CompanyUserStatus.invited) {
      delete user.id;
      delete user.color;
    } else {
      user.color = '#f59d00';
    }

    return user as CompanyUser;
  }

  private mapList(data: any) {
    if (!data || !Array.isArray(data.list)) return [];
    return (data.list as CompanyUser[]).map((u) => this.mapUser(u));
  }
}
