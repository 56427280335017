import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { filter, map, switchMap } from 'rxjs/operators';
import { toPayload } from '../../utils/rx-utils';
import { COMPANY_DEL_INVITE_LINK_SUCCESS, CompanyReqDeleteInviteLinkSuccessAction } from '../actions/company-links';
import { CompaniesStoreService } from '../services/companies-store.service';

@Injectable()
export class CompaniesEffects {
  constructor(
    private actions$: Actions, //
    private companyStore: CompaniesStoreService,
  ) {}

  public deleteLink$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType<CompanyReqDeleteInviteLinkSuccessAction>(
          COMPANY_DEL_INVITE_LINK_SUCCESS, //
        ),
        map(toPayload),
        filter((req) => req.deleteUsers),
        switchMap((req) => {
          return this.companyStore.loadOne(req.companyId);
        }),
      );
    },
    { dispatch: false },
  );
}
