import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, UntypedFormControl } from '@angular/forms';
import { MatButton } from '@angular/material/button';
import { Observable, of } from 'rxjs';
import { concatWith, distinctUntilChanged, map } from 'rxjs/operators';
import { StringAnyMap } from 'src/app/core/models/common.models';
@Component({
  template: '',
})
export abstract class BaseSingleSelectComponent implements OnChanges, ControlValueAccessor {
  @Input() public items: ReadonlyArray<any> | Readonly<StringAnyMap>;
  @Input() public idFieldName: string;
  @Input() public labelFieldName: string;
  @Input() public selectedId: string | number | undefined;
  @Input() public searchVisibleCount = 10;
  @Input() public unselectMode = false;
  @Input() public disabled = false;
  @Input() public disableRipple = false;
  @Input() public searchPlaceholder = 'Search';

  @Output() public select = new EventEmitter<any>();
  @Output() public opened = new EventEmitter<void>();
  @Output() public closed = new EventEmitter<void>();
  @Output() public unselect = new EventEmitter<any>();

  @ViewChild('searchInput') public searchInput: ElementRef;

  public itemsCount = 0;
  public selectedItem: any;
  public filteredItems: Observable<any[]>;
  public searchControl = new UntypedFormControl();

  protected onTouched: () => void = () => {};
  protected onChange: (_: any) => void = (_) => {};

  constructor(protected cdRef: ChangeDetectorRef) {
    this.filteredItems = of('').pipe(
      concatWith(this.searchControl.valueChanges.pipe(distinctUntilChanged())),
      map((searchText) => this.filterItems(searchText)),
    );
  }

  public get hasSearch(): boolean {
    return this.itemsCount >= this.searchVisibleCount;
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.items) {
      this.setItemsCount();
      this.setSelectedItem();
    }

    if (changes.selectedId) {
      this.setSelectedItem();
    }
  }

  public identify(index: number, item: any): string | number {
    return item?.[this.idFieldName] || index;
  }

  public onMenuOpened(triggerBtn?: MatButton) {
    this.onTouched();
    this.opened.next();

    if (triggerBtn) {
      setTimeout(() => this.setMenuWidth(triggerBtn), 0);
    }
  }

  public onMenuClosed() {
    this.searchControl.setValue('');
    this.closed.next();
  }

  public selectItem(item: any) {
    this.changeSelectedItem(item);
    this.onChange(this.selectedId);
  }

  public writeValue(value: any): void {
    this.selectedId = value;
    this.setSelectedItem();
  }

  public registerOnChange(fn: (_: any) => void): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdRef.detectChanges();
  }

  protected setItems(items: any[] | StringAnyMap, selectedId: string | number | undefined) {
    this.items = items;
    this.selectedId = selectedId;
    this.setItemsCount();
    this.setSelectedItem();
  }

  protected filterItems(searchText: string) {
    const items = this.items ? (Array.isArray(this.items) ? this.items : Object.values(this.items)) : [];

    if (!searchText) {
      return items;
    } else {
      const text = searchText.toLowerCase();
      return items.filter((i) => (i[this.labelFieldName] + '').toLowerCase().includes(text));
    }
  }

  protected setSelectedItem() {
    if (!this.items || !this.selectedId) {
      this.selectedItem = void 0;
    } else {
      const items = this.items;
      if (Array.isArray(items)) {
        this.selectedItem = items.find((item) => item[this.idFieldName] === this.selectedId);
      } else {
        this.selectedItem = (items as any)[this.selectedId];
      }
    }
  }

  protected setItemsCount() {
    const items = this.items;
    this.itemsCount = items ? (Array.isArray(items) ? items.length : Object.keys(items).length) : 0;
  }

  protected changeSelectedItem(item: any) {
    if (!this.unselectMode || this.selectedId !== item[this.idFieldName]) {
      this.selectedId = item[this.idFieldName];
      this.selectedItem = item;
      this.select.next(item);
    } else if (this.selectedId === item[this.idFieldName]) {
      this.selectedId = void 0;
      this.selectedItem = void 0;
      this.unselect.next(item);
    }
  }

  protected setMenuWidth(triggerBtn: MatButton) {
    const btnEl: HTMLButtonElement = triggerBtn._elementRef?.nativeElement;
    if (!btnEl || !btnEl.parentElement?.classList?.contains('selector')) return;

    const menu: HTMLDivElement | null = document.querySelector('.craft-menu-panel');
    if (!menu) return;

    const rect = btnEl.getBoundingClientRect();
    if (!rect) return;

    menu.style.width = `${rect.width}px`;
  }
}

@Component({
  selector: 'craft-single-select',
  templateUrl: './craft-single-select.component.html',
  styleUrls: ['./craft-single-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CraftSingleSelectComponent),
      multi: true,
    },
  ],
})
export class CraftSingleSelectComponent extends BaseSingleSelectComponent {
  @Input() public items: ReadonlyArray<any> | Readonly<StringAnyMap>;
  @Input() public idFieldName: string;
  @Input() public labelFieldName: string;
  @Input() public selectedId: string | undefined;
  @Input() public searchVisibleCount = 10;
  @Input() public unselectMode = false;
  @Input() public disabled = false;
  @Input() public disableRipple = false;
  @Input() public searchPlaceholder = 'Search';

  @Output() public select = new EventEmitter<any>();
  @Output() public opened = new EventEmitter<void>();
  @Output() public closed = new EventEmitter<void>();
  @Output() public unselect = new EventEmitter<any>();

  constructor(protected cdRef: ChangeDetectorRef) {
    super(cdRef);
  }
}
