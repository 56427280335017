<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Move to Company</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-subtitle" *ngIf="companyStore.map$ | async as map">
      Are you sure you want to move <b>{{ data.user.fullName }}</b> to <b>{{ $any(map[data.targetCompanyId])?.name }}</b
      >?
      <p class="mt-10">
        Please note that some Feedback Items may not be accessible to this user, according to the Categories Privacy Settings. These items will still be
        accessible to {{ $any(map[$any(data.user).companyId])?.name }} users.
      </p>
    </div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" (click)="done()" mat-button="">Confirm</button>
    </div>
  </div>
</div>
