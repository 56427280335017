import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StringTMap } from 'src/app/core/models/common.models';
import { Product } from 'src/app/core/models/products.models';
import {
  ProductAction,
  PRODUCTS_LIST_SUCCESS,
  PRODUCTS_SAVE,
  PRODUCTS_SAVE_LOGO,
  PRODUCTS_SAVE_LOGO_SUCCESS,
  PRODUCTS_SAVE_SUCCESS,
  PRODUCTS_SELECT_BY_ID,
} from 'src/app/core/store/actions/products';

export type ProductsState = {
  readonly ids: readonly string[];
  readonly products: StringTMap<Product>;
  readonly savingId: string | undefined;
  readonly selectedId: string | undefined;
};

export const initialState: ProductsState = {
  ids: [],
  products: {},
  savingId: void 0,
  selectedId: void 0,
};

export function reducer(state = initialState, action: ProductAction): ProductsState {
  switch (action.type) {
    case PRODUCTS_SELECT_BY_ID: {
      return {
        ...state,
        selectedId: action.payload,
      };
    }

    case PRODUCTS_LIST_SUCCESS: {
      const { products, ids } = action.payload;
      return {
        ...state,
        ids,
        products,
      };
    }

    case PRODUCTS_SAVE: {
      const product = action.payload;
      return {
        ...state,
        savingId: product.productId,
        products: {
          ...state.products,
          [product.productId]: { ...product },
        },
      };
    }

    case PRODUCTS_SAVE_SUCCESS:
    case PRODUCTS_SAVE_LOGO_SUCCESS: {
      return {
        ...state,
        savingId: void 0,
      };
    }

    case PRODUCTS_SAVE_LOGO: {
      const req = action.payload;
      const product = state.products[req.productId];

      return {
        ...state,
        savingId: req.productId,
        products: {
          ...state.products,
          [req.productId]: { ...product, portalFileId: req.portalFileId || void 0 },
        },
      };
    }

    default:
      return state;
  }
}

const getProducts = (state: ProductsState) => state.products;
const getProductsIdsList = (state: ProductsState) => state.ids;
const getProductsSavingId = (state: ProductsState) => state.savingId;
const getProductsSelectedId = (state: ProductsState) => state.selectedId;

export const getProductsStateSelector = createFeatureSelector<ProductsState>('productsState');
export const getProductsIdsSelector = createSelector(getProductsStateSelector, getProductsIdsList);
export const getProductsSavingIdSelector = createSelector(getProductsStateSelector, getProductsSavingId);
export const getProductsSelectedIdSelector = createSelector(getProductsStateSelector, getProductsSelectedId);
export const getProductsSelector = createSelector(getProductsStateSelector, getProducts);

export const getProductsListSelector = createSelector(
  getProductsStateSelector, //
  getProductsIdsSelector,
  (state, ids) => {
    return ids.map((id) => state.products[id]);
  },
);

export const getSelectedProductSelector = createSelector(
  getProductsStateSelector, //
  getProductsSelectedIdSelector,
  (state, selectedId) => {
    return selectedId !== void 0 ? state.products[selectedId] : void 0;
  },
);
