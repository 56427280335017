import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';
import { Destroyer } from 'src/app/core/abstract/destroyer';
import { FormsStoreService } from 'src/app/core/store/services/forms-store.service';

@Component({
  selector: 'craft-delete-form-dialog',
  templateUrl: './delete-form-dialog.component.html',
  styleUrls: ['./delete-form-dialog.component.scss'],
})
export class DeleteFormDialogComponent extends Destroyer {
  public isRequestSending = false;

  constructor(
    private formsStoreService: FormsStoreService,
    @Inject(MAT_DIALOG_DATA) public formId: string,
    private dialogRef: MatDialogRef<DeleteFormDialogComponent, string>,
  ) {
    super();
  }

  public close() {
    this.dialogRef.close();
  }

  public done() {
    this.isRequestSending = true;
    this.dialogRef.disableClose = true;

    this.formsStoreService
      .delete(this.formId)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.dialogRef.close('success');
      });
  }
}
