<div class="dialog-wrap">
  <h1 mat-dialog-title class="dialog-title ellipsis">Delete {{ tag.title }}</h1>

  <div mat-dialog-content="" class="dialog-content">
    <div class="dialog-desc">{{ (tag.ideasCount > 0 ? 'settings.text22' : 'settings.text23') | craftTerm: { count: tag.ideasCount } }}</div>
  </div>

  <div mat-dialog-actions="" class="dialog-actions d-flex justify-end">
    <span class="warning">Warning: this cannot be undone.</span>
    <div class="right-part d-flex align-items-center justify-end">
      <button class="btn btn-cancel dialog-btn" type="button" (click)="close()" [disabled]="isRequestSending" mat-button="">Cancel</button>
      <button class="btn btn-blue dialog-btn" type="button" [disabled]="isRequestSending" (click)="done()" mat-button="">Delete</button>
    </div>
  </div>
</div>
