import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CompanyUser, DBCompany } from 'src/app/core/models/companies.models';
import { PortalUser } from 'src/app/core/models/portal.models';
import { CompaniesStoreService } from 'src/app/core/store/services/companies-store.service';
import { DialogResult } from 'src/app/shared/dialogs/models';

@Component({
  templateUrl: './move-user-to-company-dialog.component.html',
  styleUrls: ['./move-user-to-company-dialog.component.scss'],
})
export class MoveUserToCompanyDialogComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { user: PortalUser | CompanyUser; targetCompanyId: string }, //
    private dialogRef: MatDialogRef<MoveUserToCompanyDialogComponent, DialogResult>,
    public readonly companyStore: CompaniesStoreService,
  ) {}

  public close() {
    this.dialogRef.close({ done: false });
  }

  public done() {
    this.dialogRef.close({ done: true });
  }
}
